const menusAll =
  [
    {
      title: '个人中心',
      path: 'Profile',
      icon: require('@/assets/new/user/u_0.png'),
      icon_selected: require('@/assets/new/user/u_0_0.png'),
      width: 20,
      height: 20,
    },
    {
      title: '我的钱包',
      path: 'MyWallet',
      icon: require('@/assets/new/user/u_1.png'),
      icon_selected: require('@/assets/new/user/u_1_1.png'),
      width: 20,
      height: 20,
    },
    {
      title: '我的U卡',
      path: 'uCards',
      icon: require('@/assets/new/user/u_10.png'),
      icon_selected: require('@/assets/new/user/u_10_1.png'),
      width: 20,
      height: 20,
    },
    {
      title: '我的订单',
      path: 'MyOrder',
      icon: require('@/assets/new/user/u_2.png'),
      icon_selected: require('@/assets/new/user/u_2_2.png'),
      width: 20,
      height: 23,
    },
    {
      title: '我的账户',
      path: '',
      icon: require('@/assets/new/user/u_3.png'),
      icon_selected: require('@/assets/new/user/u_3_3.png'),
      width: 24,
      height: 19,
      menus: [
        {
          title: '个人资料',
          path: 'UserInfo',
        },
        {
          title: '地址管理',
          path: 'MyAddress',
        },
        {
          title: '账户设置',
          path: 'AccountSafe',
        },
        {
          title: 'USDT地址薄',
          path: 'WalletList',
        }
      ],
    },
    {
      title: '消息中心',
      path: 'Message',
      icon: require('@/assets/new/user/u_4.png'),
      icon_selected: require('@/assets/new/user/u_4_4.png'),
      width: 24,
      height: 20,
    },
    {
      title: '我的优惠券',
      path: 'MyCoupon',
      icon: require('@/assets/new/user/u_5.png'),
      icon_selected: require('@/assets/new/user/u_5_5.png'),
      width: 22,
      height: 18,
    },
    {
      title: '我的供需',
      path: 'MySupply',
      icon: require('@/assets/new/user/u_6.png'),
      icon_selected: require('@/assets/new/user/u_6_6.png'),
      width: 21,
      height: 21,
    },
    {
      title: '我的收藏',
      path: 'MyCollect',
      icon: require('@/assets/new/user/u_7.png'),
      icon_selected: require('@/assets/new/user/u_7_7.png'),
      width: 20,
      height: 20,
    },
    {
      title: '关注店铺',
      path: 'Attention',
      icon: require('@/assets/new/user/u_8.png'),
      icon_selected: require('@/assets/new/user/u_8_8.png'),
      width: 20,
      height: 18,
    },
    {
      title: '我的足迹',
      path: 'History',
      icon: require('@/assets/new/user/u_9.png'),
      icon_selected: require('@/assets/new/user/u_9_9.png'),
      width: 16,
      height: 24,
    }
  ]

export default menusAll
