<template>
  <div>
    <a-layout class="layout-demo">
      <a-layout-sider
          theme="light"
          style="background-color: #F9FAFF"
          :trigger="null"
          collapsible
          :collapsed="collapsed">
        <div v-if="!collapsed" class="flex flex-direction pl-2">
          <div class="logo flex justify-center align-center">
            <a-image width="160px" height="40px" :preview="false" :src="require('@/assets/index/logo.png')"></a-image>
          </div>
          <div class="new-chat-btn cursor-pointer golbal-btn ml-3 mt-6 mb-8" @click="newChat">
            <FormOutlined :style="{fontSize: '20px'}"/>
            <div class="ml-1">
              开启新会话
            </div>
          </div>
          <div>
            <div class="text-bold ml-3">会话历史</div>
            <div v-for="(item, index) in hisData" :key="index">
              <div class="new-chat-btn-his cursor-pointer golbal-btn ml-3 mt-3 noline-hidden position-relative"
                   :class="selectIndex === index ? 'new-chat-btn-his-hover' : ''"
                   @mouseenter="selectIndex = index"
                   @click="hisChat(item, index)">
                {{ item.title }}
                <a-dropdown :trigger="['click']" v-if="selectIndex === index">
                  <a-avatar :size="30" class="position-absolute"
                            style="bottom: 0; right: 0;z-index: 9999;background-color: #DBE9FE;">
                    <template #icon>
                      <EllipsisOutlined :style="{color: '#333'}"/>
                    </template>
                  </a-avatar>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item key="0">
                        <div @click="delChat(item)">删除</div>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div v-if="collapsed" class="margin-top-xs flex flex-direction justify-center align-center">
          <div class="logo">
            <a-image :preview="false" :src="require('@/assets/logo.png')" width="50px" height="50px"></a-image>
          </div>
          <div class="margin-top-sm">
            <MenuUnfoldOutlined class="iconf-cus cursor-pointer" :style="{fontSize: '26px', color: 'gray'}"
                                @click="onCollapse"/>
          </div>
          <div class="margin-top-sm">
            <FormOutlined class="iconf-cus cursor-pointer" :style="{fontSize: '26px', color: 'gray'}" @click="newChat"/>
          </div>
        </div>
      </a-layout-sider>

      <a-layout style="background-color: #ffffff;">
        <a-layout-header style="padding-left: 20px;" theme="light">
          <div class="flex justify-between align-center">
            <MenuFoldOutlined v-if="!collapsed" class="iconf-cus-header cursor-pointer"
                              :style="{fontSize: '26px', color: 'gray'}" @click="onCollapse"/>
            <div class="mt-3">
              <a-image :preview="false" width="126px" height="25px" :src="require('@/assets/new/deepseek.png')"/>
            </div>
          </div>
        </a-layout-header>

        <a-layout style="padding: 0 24px;height: 100vh;">
          <a-layout-content class="hide-scrollbar"
                            ref="scrollContainer"
                            style="background-color: #ffffff;flex: 1; overflow-y: auto;scrollbar-width: none; -ms-overflow-style: none;">
            <!-- 默认输入框-->
            <div class="ai-content" v-if="!contentCenter">
              <div class="text-bold text-xxl margin-bottom-sm">有什么可以帮忙的？</div>
              <div class="input-textarea-end">
                <a-textarea style="width: 800px;resize: none;" @pressEnter="send" v-model:value="sendMsg"
                            :bordered="false"
                            :auto-size="{ minRows: 4, maxRows: 8 }"
                            placeholder="询问任何问题"/>
                <div class="flex justify-end align-end padding-right-xs padding-bottom-xs">
                  <SendOutlined v-if="!sendLoading" class="iconf-cus-header cursor-pointer"
                                :style="{fontSize: '26px', color: 'gray'}" @click="send"/>
                  <PauseCircleOutlined class="iconf-cus-header cursor-pointer loading-cus"
                                       :style="{fontSize: '26px', color: 'gray'}" v-if="sendLoading"
                                       @click="stopChat"/>
                </div>
              </div>
            </div>
            <!-- 聊天框 -->
            <div v-else>
              <div v-for="(item, index) in aiData" :key="index">
                <div class="ai-chat-question">
                  <span class="user">{{ item.title }}</span>
                </div>
                <div class="ai-chat-answer">
                  <div class="deepseek">
                    <div>
                      <a-avatar style="border: 1px solid #DDE9FF;" size="30px"
                                :src="require('@/assets/logo.png')"></a-avatar>
                    </div>
                    <div class="ml-2 flex">
                      <!-- 加载 -->
                      <div class="cu-center" v-if="index === aiData.length - 1 && !rawMarkdown">
                        <a-spin :spinning="sendLoading"/>
                      </div>
                      <div v-html="item.content" class="markdown-body"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-layout-content>
          <a-layout-footer style="background-color: #ffffff;">
            <div class="ai-footer">
              <div class="flex justify-center align-center flex-direction" v-if="contentCenter">
                <div class="input-textarea-end">
                  <a-textarea style="width: 800px;resize: none;" @pressEnter="send" v-model:value="sendMsg"
                              :bordered="false"
                              :auto-size="{ minRows: 3, maxRows: 8 }"
                              placeholder="询问任何问题"/>
                  <div class="flex justify-end align-end padding-right-xs padding-bottom-xs">
                    <SendOutlined v-if="!sendLoading" class="iconf-cus-header cursor-pointer"
                                  :style="{fontSize: '26px', color: 'gray'}" @click="send"/>
                    <PauseCircleOutlined class="iconf-cus-header cursor-pointer loading-cus"
                                         :style="{fontSize: '26px', color: 'gray'}" v-if="sendLoading"
                                         @click="stopChat"/>
                  </div>
                </div>
              </div>
            </div>
          </a-layout-footer>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { closeStream, delByChatMsg, generateId, hisAiMsg, hisByChatMsg, saveAiMsg } from '@/api/ai'
import MarkdownIt from 'markdown-it'
import { BASE_URL } from '@/config/index.js'
import hljs from 'highlight.js'
import 'highlight.js/styles/github-dark.css'

import {
  EllipsisOutlined,
  FormOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PauseCircleOutlined,
  SendOutlined
} from '@ant-design/icons-vue'

let codeDataList = []

// 初始化 MarkdownIt库
const md = MarkdownIt({
  html: true, // 允许 HTML
  linkify: true, // 识别 URL
  typographer: true, // 智能排版
  highlight: function (str, lang) {
    let preCode = ''
    try {
      preCode = hljs.highlightAuto(str).value
    } catch (err) {
      preCode = markdownIt.utils.escapeHtml(str)
    }
    // 以换行进行分割
    const lines = preCode.split(/\n/)
        .slice(0, -1)
    // 添加自定义行号
    let html = lines.map((item, index) => {
      // 去掉空行
      if (item == '') {
        return ''
      }
      return '<li><span class="line-num" data-line="' + (index + 1) + '"></span>' + item +
          '</li>'
    })
        .join('')
    html = '<ol style="padding: 0px 30px;">' + html + '</ol>'

    // 代码复制功能
    codeDataList.push(str)
    let htmlCode =
        `<div style="background:#0d1117;margin-top: 5px;color: #888;padding:5px 0;border-radius: 5px;">`
    // #ifndef MP-WEIXIN
    htmlCode += `<div style="text-align: end;font-size: 12px;">`
    htmlCode +=
        `${lang}<a class="copy-btn" code-data-index="${codeDataList.length - 1}" style="cursor: pointer;"></a>`
    htmlCode += `</div>`
    // #endif
    htmlCode +=
        `<pre class="hljs" style="padding:0 8px;margin-bottom:5px;overflow: auto;display: block;border-radius: 5px;"><code>${html}</code></pre>`
    htmlCode += '</div>'
    return htmlCode
  }
})

export default {
  name: 'ChatComponent',
  components: {
    SendOutlined,
    PauseCircleOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    FormOutlined,
    EllipsisOutlined
  },
  data() {
    return {
      chatId: '',
      collapsed: false,
      sendMsg: '',
      sendMsgTitle: '',
      token: '',
      sendLoading: false,
      contentCenter: false,
      rawMarkdown: '',
      eventSource: null,
      fullContent: '',
      aiData: [],
      hisData: [],
      selectIndex: '',
    }
  },
  computed: {
    formattedContent() {
      return
    },
  },
  watch: {},
  mounted() {
    const token = !this.$store.getters.token ? sessionStorage.getItem('token') : this.$store.getters.token
    if (!token) {
      this.$router.push({ path: '/login' })
      return
    }
    this.token = token
    this.loadId()
    this.loadHis()
    // 监听内容变化
    const observer = new MutationObserver(() => {
      this.scrollToBottom()
    })

    document.getElementById('app').style.width = '100vw'

    // 3. 代码块需要手动触发 highlight.js，否则可能无效
    this.$nextTick(() => {
      document.querySelectorAll('pre code')
          .forEach((block) => {
            hljs.highlightElement(block)
          })
    })
  },
  methods: {
    stopChat() {
      closeStream()
          .then(res => {
            if (this.eventSource) {
              this.sendLoading = false
              this.eventSource.close()
              this.eventSource = null
              if (!this.aiData.slice(-1)[0].content) {
                this.aiData.pop()
                this.rawMarkdown = ''
                this.loadHis()
              } else {
                this.saveAIChatHis()
              }
            }
          })
    },
    delChat(chatItem) {
      delByChatMsg(chatItem.chatId)
          .then(res => {
            this.loadHis()
            this.loadId()
          })
    },
    hisChat(chatItem, index) {
      hisByChatMsg(chatItem.chatId)
          .then(res => {
            this.aiData = res.data
            if (this.aiData.length > 0) {
              this.contentCenter = true
              this.chatId = this.aiData[0].chatId
              this.selectIndex = index

              this.$nextTick(() => {
                this.scrollToBottom()
              })
            }
          })
    },
    loadHis() {
      hisAiMsg()
          .then(res => {
            this.hisData = res.data
          })
    },
    saveAIChatHis() {
      let data = {
        chatId: this.chatId,
        title: this.sendMsgTitle,
        content: this.fullContent,
        reqContent: this.rawMarkdown
      }
      saveAiMsg(data)
          .then(res => {
            if (res.code !== 200) {
              console.error('保存服务异常')
            }
            this.rawMarkdown = ''
            this.loadHis()
          })
    },
    send() {
      if (this.eventSource) {
        console.warn('SSE 已经在运行')
        return
      }
      if (!this.sendMsg) {
        return
      }
      this.$nextTick(() => {
        this.scrollToBottom()
      })

      this.sendMsgTitle = this.sendMsg
      this.aiData.push({
        chatId: this.chatId,
        title: this.sendMsgTitle,
        content: '',
      })
      this.contentCenter = true
      this.sendLoading = true
      this.eventSource = new EventSource(`${BASE_URL}deepseek/chat/stream?query=${this.sendMsg}&token=${this.token}&chatId=${this.chatId}`)
      this.sendMsg = ''
      // 监听服务器发送的消息
      this.eventSource.onmessage = (event) => {
        if (event.data === '[DONE]') {
          this.sendLoading = false
          this.eventSource.close()
          this.eventSource = null
          this.$nextTick(() => {
            this.scrollToBottom()
          })
          this.saveAIChatHis()
        } else {
          // 追加 Markdown 数据
          const json = JSON.parse(event.data)
          if (json.choices && json.choices[0].delta.content) {
            this.rawMarkdown += json.choices[0].delta.content // 追加 Markdown 数据
          }
          this.processMarkdown() // 处理 Markdown
        }
      }
      // 处理错误
      this.eventSource.onerror = (error) => {
        this.sendLoading = false
        this.eventSource.close()
        this.eventSource = null
        this.rawMarkdown = ''
      }
      this.eventSource.oncomplete = () => {
        this.sendLoading = false
        this.eventSource.close()
        this.eventSource = null
        this.rawMarkdown = ''
      }
    },
    newChat() {
      if (this.contentCenter) {
        this.contentCenter = false
        this.sendLoading = false

        this.loadId()
      }
    },
    loadId() {
      var that = this
      generateId()
          .then((result) => {
            that.chatId = result.data
            this.contentCenter = false
            this.sendLoading = false
            if (this.eventSource) {
              this.eventSource.close()
              this.eventSource = null
            }
            this.rawMarkdown = ''
            this.aiData = []
            this.$nextTick(() => {
              this.scrollToBottom()
            })
          })
          .catch((err) => {
          })
    },
    onCollapse() {
      this.collapsed = !this.collapsed
    },
    onClickMenuItem(key) {
      this.$Message.info({
        content: `You select ${key}`,
        showIcon: true
      })
    },
    processMarkdown() {
      // 仅在段落结束或代码块完整时更新渲染
      let htmlString = ''
      // 修改转换结果的htmlString值 用于正确给界面增加鼠标闪烁的效果
      // 判断markdown中代码块标识符的数量是否为偶数
      if (this.rawMarkdown.split('```').length % 2) {
        let msgContent = this.rawMarkdown
        if (msgContent[msgContent.length - 1] != '\n') {
          msgContent += '\n'
        }
        msgContent += ' <span class="cursor">|</span>'
        htmlString = md.render(msgContent)
      } else {
        htmlString = md.render(this.rawMarkdown) + ' \n <span class="cursor">|</span>'
      }
      this.fullContent = htmlString

      this.aiData[this.aiData.length - 1].content = this.fullContent

      this.$nextTick(() => {
        this.scrollToBottom()
      })
    },
    isCodeBlockComplete() {
      // 统计代码块 ` ``` ` 的数量，必须是偶数才完整
      const count = (this.rawMarkdown.match(/```/g) || []).length
      return count % 2 === 0
    },
    scrollToBottom() {
      const content = this.$refs.scrollContainer
      if (content) {
        content.$el.scrollTop = content.$el.scrollHeight
      }
    },
  },
  beforeUnmount() {
    // 组件销毁时关闭 SSE 连接
    if (this.eventSource) {
      this.eventSource.close()
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(#app) {
  width: 100px !important;
}

.noline-hidden {
  white-space: nowrap;
  overflow: hidden;
}

/* 确保代码块的容器不会超出屏幕 */
.markdown-body {
  width: 758px;
  font-size: 28rpx;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 45rpx;
}

/* 处理 <pre> 标签，避免代码超出屏幕 */
:deep(.markdown-body pre) {
  background: #0C1017;
  /* 可调整代码块背景色 */
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  /* 允许横向滚动 */
  white-space: pre-wrap;
  /* 让内容换行 */
  word-wrap: break-word;
  width: 758px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* 处理 <code> 标签 */
:deep(.markdown-body code) {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  background: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
}

/* 代码高亮（适用于 highlight.js） */
:deep(.markdown-body pre code) {
  display: block;
  padding: 0;
  background: none;
  /* 避免 highlight.js 额外的背景影响 */
  overflow-x: auto;
  -ms-overflow-style: none; /* 隐藏 IE/Edge 旧版滚动条 */
  scrollbar-width: none; /* 隐藏 Firefox 滚动条 */
}

:deep(.markdown-body pre ol) {
  list-style-type: none;
}

/* 适配 highlight.js 的样式 */
:deep(.hljs) {
  display: block;
  overflow-x: auto;
  padding: 1em;
  background: #282c34;
  /* 暗色背景 */
  color: #abb2bf;
  /* 默认代码颜色 */
  border-radius: 5px;
}

/* 代码高亮的样式调整 */
:deep(.hljs-keyword),
:deep(.hljs-selector-tag),
:deep(.hljs-title) {
  color: #c678dd;
}

:deep(.hljs-comment) {
  color: #7d8799;
}

:deep(.hljs-string),
:deep(.hljs-number) {
  color: #98c379;
}

:deep(.hljs-function) {
  color: #61afef;
}

:deep(.hljs-built_in),
:deep(.hljs-type) {
  color: #e5c07b;
}

/* 适配移动端 */
@media screen and (max-width: 768px) {
  .markdown-body pre {
    font-size: 14px;
    /* 移动端适当缩小字体 */
    padding: 8px;
  }

  .markdown-body code {
    font-size: 14px;
  }
}

.new-chat-btn {
  height: 44px;
  width: 131px;
  font-size: 14px;
  color: #4D6BFE;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DBE9FE;
  border-radius: 10px;

  padding-left: 15px;
  padding-right: 15px;
}

.new-chat-btn-his {
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;

  padding-left: 10px;
}

.new-chat-btn-his-hover {
  border-radius: 10px;
  background-color: #DBE9FE;
}

.new-chat-btn-his:hover {
  border-radius: 10px;
  background-color: #DBE9FE;
}

.ai-chat-answer {
  width: 800px;
  margin: 20px auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .deepseek {
    display: flex;
  }
}

.ai-chat-question {
  width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .user {
    background-color: #EFF5FF;
    color: #333333;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.input-textarea-end {
  max-width: 800px;
  border: 1px solid #E9E9E9;
  border-radius: 10px;
  background-color: #F3F3F6;
}

.loading-cus {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ai-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.ai-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

:deep(.ant-layout) {
  background-color: #ffffff !important;
}

.iconf-cus:hover {
  width: 40px;
  height: 40px;
  background-color: #E8E8E8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.iconf-cus-header:hover {
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: #E8E8E8;
  border-radius: 10px;
}

body {
  width: 100vw !important;
  height: 100% !important;
}

#app {
  width: 100vw !important;
  height: 100vh !important;
}

.layout-demo {
  height: 100vh;
  border: 1px solid var(--color-border);
}

.logo {
  margin: 12px 8px;
}

:deep(.ant-layout .ant-layout-header) {
  background: #fff;
  color: #333333;
}

.layout-demo :deep(.arco-layout-header) {
  height: 64px;
  line-height: 64px;
}

.layout-demo :deep(.arco-layout-footer) {
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 48px;
}

.layout-demo :deep(.arco-layout-content) {
  font-weight: 400;
  font-size: 14px;
}

.layout-demo :deep(.arco-layout-footer),
.layout-demo :deep(.arco-layout-content) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  font-stretch: condensed;
  text-align: center;
}

@import 'highlight.js/styles/github-dark.css'
</style>
