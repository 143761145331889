<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="visible"
             :maskClosable="false"
             :afterClose="cancel"
             :destroyOnClose="true"
             :width="520"
             :body-style="{padding: '10px 0px'}"
             @close="cancel"
             @cancel="cancel">
      <template #title>
        <div class="cu-center">请选择地址</div>
      </template>
      <div class='item-pop' style="border: 1px solid #EEEEEE; border-radius: 5px;" v-for="(item,index) in addressList"
           :key="index">
        <div class='address' @click='changeAdd(item.id)'>
          <div class='consignee'>
            收货人：{{ item.realName }}
            <text class='phone'>{{ item.phone }}</text>
          </div>
          <div>
            收货地址：{{ item.province }}{{ item.city }}{{ item.district }}{{
              item.street
            }}{{ item.detail }}
          </div>
        </div>
      </div>
      <template #footer>
        <div class="text-gray text-df flex justify-end cursor-pointer golbal-btn"
             @click="toAddress">
          新增收货地址?
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'Evaluate',
  props: {
    addressList: {
      type: Array,
      default: [],
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    toAddress() {
      this.$router.push({ path: '/MyAddress' })
    },
    changeAdd(addressId) {
      this.$emit('change', addressId)
    },
    cancel() {
      this.$emit('change')
    },
  },
}
</script>

<style scoped lang="scss">
.item-pop {
  background-color: #fff;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px 30px;
}

.item-pop:hover {
  background-color: #EEEEEE;
  color: #2b85e4 !important;
}

.item-pop .address {
  padding: 17.5px 0;
  font-size: 14px;
  color: #282828;
}

.address:hover {
  color: #2b85e4 !important;
}

.item-pop .address .consignee {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.item-pop .address .consignee .phone {
  margin-left: 12.5px;
}

.item-pop .operation {
  height: 41.5px;
  font-size: 14px;
  color: #282828;
}

.item-pop .operation .radio text {
  margin-left: 6.5px;
}

.item-pop .operation .iconfont {
  color: #2c2c2c;
  font-size: 17.5px;
  vertical-align: -1px;
  margin-right: 5px;
}

.item-pop .operation .iconfont.icon-shanchu {
  margin-left: 17.5px;
  font-size: 19px;
}
</style>
