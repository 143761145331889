import request from '@/utils/api_req.js'

/**
 * AI窗口ID
 * @param typeId
 * @returns {*}
 */
export function generateId() {
  return request.get('deepseek/chat/generate')
}

export function saveAiMsg(data) {
  return request.post('deepseek/chat/save', data)
}

export function hisAiMsg() {
  return request.get('deepseek/chat/his')
}

export function hisByChatMsg(chatId) {
  return request.get(`deepseek/chat/hisByChatId/${chatId}`)
}

export function delByChatMsg(chatId) {
  return request.get(`deepseek/chat/delByChatId/${chatId}`)
}

export function closeStream() {
  return request.get(`deepseek/chat/close/stream`)
}

