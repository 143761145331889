<template>
  <div class="wrapper">
    <a-card :body-style="{padding: '40px 10px'}" title="我的U卡(测试版)">
      <template #extra>
        <RedoOutlined class="cursor-pointer golbal-btn text-xxl" @click="refresh"/>
      </template>
      <div class="flex">
        <div class="padding-left-sm flex">
          <a-image v-if="selectIndex === 0 || selectIndex === 2" style="border-radius: 16px;" :width="364"
                   :height="228"
                   :src="require('@/assets/new/ucards/cards_f.png')" :preview="false"/>
          <a-spin size="large" :spinning="loadingBack" v-if="selectIndex === 1">
            <div class="position-relative" v-if="selectIndex === 1">
              <a-image v-if="selectIndex === 1" style="border-radius: 16px;" :width="364" :height="228"
                       :src="require('@/assets/new/ucards/cards_b.png')" :preview="false"/>
              <div class="position-absolute text-white" style="top: 32px;left: 10px;width: 100%;height: 100%;">
                <div class="text-xxxl">{{ cardBackInfo.cardNo ? cardBackInfo.cardNo : '' }}</div>
              </div>
              <div class="position-absolute text-white" style="bottom: 30px;left: 10px;width: 100%;">
                <div class="flex">
                  <div>
                    <div>{{ cardBackInfo.firstName ? cardBackInfo.firstName : '' }}</div>
                    <div>{{ cardBackInfo.lastName ? cardBackInfo.lastName : '' }}</div>
                    <div class="text-gray">Name</div>
                  </div>
                </div>
              </div>
              <div class="position-absolute text-white" style="bottom: 30px;left: 150px;width: 100%;">
                <div class="flex">
                  <div>
                    <div>{{
                        (cardBackInfo.expMonth ? cardBackInfo.expMonth : '') + '/' + (cardBackInfo.expYear ? cardBackInfo.expYear : '')
                      }}
                    </div>
                    <div class="text-gray">VALID THRU</div>
                  </div>
                </div>
              </div>
              <div class="position-absolute text-white" style="bottom: 30px;left: 260px;width: 100%;">
                <div class="flex">
                  <div>
                    <div>{{ cardBackInfo.cvv ? cardBackInfo.cvv : '' }}</div>
                    <div class="text-gray">CVV</div>
                  </div>
                </div>
              </div>
            </div>
          </a-spin>
        </div>
        <div class="flex flex-direction align-start padding-left-sm">
          <div class="margin-bottom margin-top-xs flex justify-between align-center" style="width: 100%;">
            <div>
              <a-space :size="16">
                <div v-if="auditStatus === 1 && selectIndex === 0" class="cursor-pointer golbal-btn-cz-tx"
                     @click="tab(1)">
                  <EyeInvisibleOutlined :style="{fontSize: '20px'}"/>
                </div>
                <div v-if="auditStatus === 1 && selectIndex === 1" class="cursor-pointer golbal-btn-cz-tx"
                     @click="tab(0)">
                  <EyeOutlined :style="{fontSize: '20px'}"/>
                </div>
                <div v-if="auditStatus === 1" class="cursor-pointer golbal-btn-cz-tx usdt-amount-button-cz"
                     @click="isSetPwd('cz')">
                  我要充值
                </div>
                <div v-if="auditStatus === 1" class="cursor-pointer golbal-btn-cz-tx usdt-amount-button-tx"
                     @click="isSetPwd('tx')">
                  我要转出
                </div>
                <div v-if="auditStatus === -1"
                     class="cursor-pointer golbal-btn usdt-amount-button usdt-amount-button-create"
                     @click="tab(2)">
                  申请U卡
                </div>
                <div v-if="auditStatus === 1 && selectIndex === 1"
                     class="cursor-pointer golbal-btn usdt-amount-button-copy"
                     @click="copy">
                  <CopyOutlined/>
                  复制卡信息
                </div>
              </a-space>
            </div>
          </div>
          <a-row :gutter="[20,24]" style="width: 950px;height: 160px;flex:1;">
            <a-col :span="8">
              <div class="top-pane-0">
                <div class="flex justify-start align-center">
                  <a-image style="border-radius: 16px;" :width="36" :height="36"
                           :src="require('@/assets/new/ucards/card.png')" :preview="false"/>
                  <div class="padding-left-xs usdt-amount">
                    卡可用余额
                  </div>
                </div>
                <div class="margin-top-sm text-xxl text-bold text-black">
                  $ {{ amount.available }}
                </div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="top-pane-1">
                卡累计消费: <span class="text-bold ml-1"> ${{ cardInfo.Consumption }}</span>
              </div>
              <div class="top-pane-1 margin-top-xs">
                卡累计充值: <span class="text-bold ml-1"> ${{ cardInfo.TransferIn }}</span>
              </div>
            </a-col>
            <a-col :span="7">
              <div class="top-pane-1">
                卡累计退款: <span class="text-bold ml-1"> ${{ cardInfo.Credit }}</span>
              </div>
              <div class="top-pane-1 margin-top-xs">
                卡累计转出: <span class="text-bold ml-1"> ${{ cardInfo.TransferOut }}</span>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-card>

    <a-card v-if="auditStatus === 1 && postData.postStatus < 4" class="mt-2">
      <div class="flex justify-between align-center">
        <div class="flex">
          <div class="padding-right-xs" v-if="postData.postStatus !== 3">寄送服务</div>
          <div v-if="postData.postStatus === 3"
               style="border-radius: 6px;"
               class="flex bg-gray padding-left-sm padding-right-sm justify-start align-center">
            <div>
              {{ postData.expressName }}
            </div>
            <div class="margin-left-sm">
              {{ postData.trackingNumber }}
            </div>
          </div>
        </div>
        <div class="cursor-pointer golbal-btn-cz-tx usdt-amount-button-yj"
             @click="postData.postStatus === -1 || postData.postStatus === null ? showAddress() : postData.postStatus === 3 ? postSign() : ''">
          {{
            postData.postStatus === -1 ? '申请邮寄' : postData.postStatus === 0 ? '申请审批中' :
                postData.postStatus === 1 ? '待发货' : postData.postStatus === 2 ? '申请失败' :
                    postData.postStatus === 3 ? '签收' :
                        postData.postStatus === 4 ? '已签收送达' : '申请邮寄'
          }}
          <!-- 邮寄状态 0:申请中 1:申请成功 2:申请失败 3:已邮寄 4:已签收送达 -->
        </div>
      </div>
    </a-card>

    <!--  申请U卡  -->
    <a-card class="mt-2" v-if="selectIndex === 2" :body-style="{padding: '40px 24px'}" title="申请U卡">
      <a-form :model="cardData"
              :wrapper-col="{ span: 14 }"
              @finish="onFinish">
        <a-form-item label="手机" name="phone"
                     :has-feedback="showClearIconPhone"
                     :validate-status="phoneHelp ? 'error' :'success'"
                     :help="phoneHelp" :rules="[{ required: true, message: '请输入手机号' }]">
          <a-input :disabled="auditStatus === 1 || auditStatus === 0" class="input-cell"
                   :placeholder="$t('signUp.s_00022')" v-model:value="cardData.phone"
                   @input="$InputEvent.OnlyNumericLetters($event)"
                   v-in-number
                   @change="onKeyInputPhone"
                   :allow-clear="!showClearIconPhone">
            <template #prefix>
              <Vue3CountryIntl
                  schema="popover"
                  :selectedText="$t('signUp.s_00023')"
                  :useChinese="true"
                  :searchInputPlaceholder="$t('common.com_002')"
                  v-model="cardData.countryCode"
                  v-model:visible="countryView"
                  @change="changeCountry">
                <Vue3CountryFlag :value="cardData.iso2"
                                 class="cursor-pointer"
                                 @click="countryView = !countryView">
                  <template v-slot="{country}">
                    <span style="margin-left: 3px;">{{ '+' + country.dialCode }}</span>
                    <Icon type="md-arrow-dropdown"/>
                  </template>
                </Vue3CountryFlag>
              </Vue3CountryIntl>
              <a-divider type="vertical" style="margin-left: -6px;"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item label="邮箱" name="email"
                     :rules="[{ required: true, message: '请输入邮箱' }, { type: 'email', message: '请输入正确的邮箱' }]">
          <a-input :disabled="auditStatus === 1 || auditStatus === 0" class="input-cell" v-model:value="cardData.email"
                   v-no-chinese
                   placeholder="请输入邮箱"></a-input>
        </a-form-item>
        <a-form-item label="姓氏" name="firstName" :rules="[{ required: true, message: '请输入姓氏' }]">
          <a-input :disabled="auditStatus === 1 || auditStatus === 0" class="input-cell"
                   v-model:value="cardData.firstName"
                   v-only-letter
                   @input="checkLetter"
                   placeholder="请输入姓氏(输入英文)"></a-input>
        </a-form-item>
        <a-form-item label="名称" name="lastName" :rules="[{ required: true, message: '请输入名称' }]">
          <a-input :disabled="auditStatus === 1 || auditStatus === 0" class="input-cell"
                   v-model:value="cardData.lastName"
                   v-only-letter
                   @input="checkLetter1"
                   placeholder="请输入名称(输入英文)"></a-input>
        </a-form-item>
        <a-form-item label="">
          <a-button v-if="auditStatus === -1 || auditStatus === 2" type="link" class="from-btn margin-150"
                    html-type="submit">
            {{ auditStatus === 2 ? '重新申请' : '提交申请' }}
          </a-button>
          <div v-if="auditStatus === 1" class="margin-150">申请成功</div>
          <div v-if="auditStatus === 2" class="margin-150 mt-2">申请驳回: <span class="text-red">{{
              cardData.auditCause
            }}</span></div>
          <div v-if="auditStatus === 0" class="margin-150 text-green">正在审核中...</div>
        </a-form-item>
      </a-form>
    </a-card>
    <!-- 交易列表-->
    <a-card class="mt-2" v-else :body-style="{padding: '40px 24px'}" title="交易列表">
      <a-table :pagination="false" :loading="loading" :columns="columns" :dataSource="transferList">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'orderTime'">
            <div class="text-blue">
              {{ record.orderTime }}
            </div>
          </template>
          <template v-if="column.key === 'orderNo'">
            <div class="text-gray">{{ record.orderNo }}</div>
          </template>
          <template v-if="column.key === 'orderInfo'">
            <div class="text-gray">{{ record.orderInfo }}</div>
          </template>
          <template v-if="column.key === 'orderFee'">
            <div class="text-gray">{{ record.orderFee }}</div>
          </template>
          <template v-if="column.key === 'orderRemark'">
            <div class="text-gray">{{ record.orderRemark }}</div>
          </template>
          <template v-if="column.key === 'orderType'">
            <div class="text-bold" :class="record.orderType === 'TransferOut' ? 'text-gray' : 'text-green'">
              {{
                record.orderType === 'TransferOut' ? '转出' : record.orderType === 'Fee_Consumption' ? '交易授权费用' : record.orderType === 'Consumption' ? '消费支出' :
                    record.orderType === 'TransferIn' ? '充值' : record.orderType === 'Declined_Fee' ? '交易失败费' : record.orderType === 'Credit' ? '交易退款' :
                        record.orderType === 'Reversal' ? '交易撤销' : '---'
              }}
            </div>
          </template>
          <template v-if="column.key === 'orderAmount'">
            <div class="text-red text-bold">
              {{ record.orderAmount ? record.orderAmount : '0.00' }}
            </div>
          </template>
          <template v-if="column.key === 'orderStatus'">
            <div class="text-bold" :class="record.orderStatus === 'Closed' ? 'text-green' : 'text-red'">
              {{
                record.orderStatus === 'Closed' ? '已完成' : record.orderStatus === 'Pending' ? '处理中' : record.orderStatus === 'Fail' ? '交易失败' : '---'
              }}
            </div>
          </template>
        </template>
      </a-table>
      <!-- 分页 -->
      <div class="page-size margin-top margin-bottom-lg" v-if="transferList && transferList.length > 0">
        <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                      :show-size-changer="false"
                      @page-size-change="changePageSize"/>
      </div>
    </a-card>
    <!-- 申请 -->
    <a-modal v-model:open="xYVisible" :title="xYVisibleFlag === 1 ? '充值' : '提取'"
             :width="620"
             centered
             @cancel="cancel()"
             :cancelText="$t('common.com_001')">
      <a-form :model="formData"
              @finish="xYVisibleFlag === 1 ? confirmCz() : confirmTx()">
        <a-form-item name="amount" :rules="[{ required: true, message: '请输入金额' }]" class="mb-1 mt-2">
          <template #label>{{ xYVisibleFlag === 1 ? '充值' : '提取' }}金额</template>
          <a-input-number class="input-cell" :placeholder="'输入提币金额, 最小10USDT'"
                          v-model:value="formData.amount" :min="10" @change="queryRate">
            <template #suffix>
              <a-divider type="vertical"/>
              <div class="cursor-pointer golbal-btn" @click="maxClick">最大</div>
            </template>
          </a-input-number>
        </a-form-item>
        <div class="flex justify-end mt-2">
          <div style="font-size: 14px;" class="text-gray">
            手续费率: {{ formData.rate }}%
          </div>
        </div>
        <a-form-item name="payPassword" :rules="[{ required: true, message: '请输入支付密码' }]" class="mb-1 mt-6">
          <template #label>支付密码</template>
          <a-input-password :maxlength="6" class="input-cell" placeholder="请输入支付密码"
                            v-no-chinese
                            v-model:value="formData.payPassword">
          </a-input-password>
        </a-form-item>
        <div class="flex flex-direction justify-end align-end text-gray mt-2" style="font-size: 14px;width: 100%;">
          <div class="text-red">
            到账金额: {{ formData.factAmount }} USDT
          </div>
          <div class="text-gray">
            余额: {{ formData.balance }} USDT
          </div>
        </div>
        <a-form-item class="mt-8" label="">
          <div class="flex justify-end">
            <a-spin size="mini" :spinning="spinningLoading">
              <a-button type="primary" html-type="submit">
                {{ xYVisibleFlag === 1 ? '充值' : '提取' }}
              </a-button>
            </a-spin>
          </div>
        </a-form-item>
      </a-form>
      <template #footer>
      </template>
    </a-modal>
    <!-- 地址选择 -->
    <ChangeAddress :address-list="addressList" :visible="showPop" @change="changeAdd"/>
    <!-- 确认支付密码 -->
    <Payment :visible="payVisible" :btnTitle="'确认'" @complete="onComplete"/>
  </div>
</template>

<script>
import {
  cardBackInfo,
  cardInfo,
  cardPostSave,
  cardPostSign,
  cardPostStatus,
  cardTransactions,
  create,
  isCreate,
  queryTransferRate,
  transferIn,
  transferOut,
} from '@/api/cards'
import { checkPaypwd, checkPhone, getAddressList, getMyAccountApi, isSet } from '@/api/user'
import { CopyOutlined, EyeInvisibleOutlined, EyeOutlined, RedoOutlined } from '@ant-design/icons-vue'
import { Modal } from 'ant-design-vue'
import OrderSteps from '@/components/orderSteps/index.vue'
import payCode from '@/components/payCode/index.vue'
import AfterSales from '@/components/afterSales/index.vue'
import ChangeAddress from '@/components/changeAddress/index.vue'
import Payment from '@/components/payment/index.vue'

export default {
  name: 'MyUCards',
  components: {
    ChangeAddress,
    AfterSales,
    payCode,
    OrderSteps,
    RedoOutlined,
    CopyOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    Payment,
  },
  data() {
    return {
      showPop: false,
      addressList: [],
      selectIndex: 0,
      isCreated: false,
      phoneHelp: '',
      showClearIconPhone: false,
      countryView: false,
      cardData: {
        iso2: 'us',
        countryCode: '1',
        phone: '',
        email: '',
        firstName: '',
        lastName: ''
      },
      auditStatus: -1,
      cardInfo: {},
      amount: {
        available: 0.00,
      },
      transferList: [],
      page: 1,
      limit: 10,
      total: 0,
      loading: false,
      columns: [
        {
          title: '交易时间',
          key: 'orderTime',
          align: 'center'
        },
        {
          title: '交易ID',
          key: 'orderNo',
          align: 'center'
        },
        {
          title: '类型',
          key: 'orderType',
          align: 'center'
        },
        {
          title: '交易金额($)',
          key: 'orderAmount',
          align: 'center'
        },
        {
          title: '手续费($)',
          key: 'orderFee',
          align: 'center'
        },
        // {
        //   title: '交易详情',
        //   key: 'orderInfo',
        //   align: 'center'
        // },
        {
          title: '交易状态',
          key: 'orderStatus',
          align: 'center'
        },
        // {
        //   title: '备注',
        //   key: 'orderRemark',
        //   align: 'center'
        // }
      ],
      cardBackInfo: {},
      loadingBack: false,
      xYVisible: false,
      xYVisibleFlag: 1,
      formData: {
        amount: 10,
        rate: '',
        balance: 0,
        factAmount: 0,
        payPassword: '',
      },
      spinningLoading: false,
      postData: {
        postStatus: -1,
        expressName: '',
        trackingNumber: ''
      },
      payVisible: false,
    }
  },
  mounted() {
    this.cardIsCreate()
    this.userBalance()
    this.queryRate()
    this.queryCardPostStatus()
  },
  methods: {
    refresh() {
      this.cardIsCreate()
      this.userBalance()
      this.queryRate()
      this.queryCardPostStatus()
    },
    changeAdd(addressId) {
      if (!addressId) {
        return this.showPop = false
      }
      cardPostSave(addressId)
          .then(res => {
            this.showPop = false
            this.queryCardPostStatus()
          })
          .catch(error => {
            this.showPop = false
            this.$Message.error(error)
          })
    },
    queryCardPostStatus() {
      cardPostStatus()
          .then(res => {
            this.postData = res.data
          })
    },
    postSign() {
      cardPostSign()
          .then((res) => {
            if (res.code === 200) {
              this.queryCardPostStatus()
              this.$Message.success('签收成功')
            }
          })
    },
    showAddress() {
      getAddressList()
          .then(res => {
            this.addressList = res.data
            if (!this.addressList || this.addressList.length <= 0) {
              Modal.confirm({
                title: '提醒',
                centered: true,
                content: '您还未设置收货地址,是否前去设置?',
                onOk: () => {
                  this.$router.push({ path: '/MyAddress' })
                },
                onCancel: () => {
                }
              })
            } else {
              this.showPop = true
            }
          })
          .catch(err => {
            this.$Message.error(err)
          })
    },
    confirmCz() {
      if (!this.formData.amount || this.formData.amount < 10) {
        this.$Message.error('金额只能大于10')
        return
      }
      if (!this.formData.payPassword || this.formData.payPassword.length < 6) {
        this.$Message.error('请输入支付密码')
        return
      }
      if (Number.valueOf(this.formData.balance) <= 0 || Number.valueOf(this.formData.factAmount) > Number.valueOf(this.formData.balance)) {
        Modal.confirm({
          title: '提醒',
          centered: true,
          content: '您余额不足,前去充值?',
          onOk: () => {
            this.$router.push({ path: '/MyWallet' })
          },
          onCancel: () => {
          }
        })
        return
      }
      this.confirmCzApi()
    },
    confirmCzApi() {
      this.spinningLoading = true
      let data = {
        payPassword: this.formData.payPassword,
        amount: this.formData.amount,
      }
      transferIn(data)
          .then(res => {
            this.spinningLoading = false
            this.xYVisible = false
            if (res.code === 200) {
              this.queryCardInfo()
              this.queryTransList()
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    confirmTx() {
      if (!this.formData.amount || this.formData.amount < 10 || Number.valueOf(this.formData.factAmount) <= 0) {
        this.$Message.error('金额只能大于10')
        return
      }
      if (!this.formData.payPassword || this.formData.payPassword.length < 6) {
        this.$Message.error('请输入支付密码')
        return
      }
      this.confirmTxApi()
    },
    confirmTxApi() {
      this.spinningLoading = true
      let data = {
        payPassword: this.formData.payPassword,
        amount: this.formData.amount,
      }
      transferOut(data)
          .then(res => {
            this.spinningLoading = false
            this.xYVisible = false
            if (res.code === 200) {
              this.queryCardInfo()
              this.queryTransList()
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    maxClick() {
      this.formData.amount = this.formData.balance
    },
    cancel() {
      this.formData = {
        amount: 10,
        rate: '',
        balance: 0,
        factAmount: 0,
        payPassword: '',
      }
      this.xYVisible = false
    },
    userBalance() {
      getMyAccountApi()
          .then(res => {
            this.formData.balance = res.data.nowMoney
          })
    },
    queryRate() {
      let data = {
        amount: this.formData.amount,
      }
      queryTransferRate(data)
          .then(res => {
            if (res.code === 200) {
              this.formData.factAmount = res.data.amount
              this.formData.rate = res.data.rate
            }
          })
    },
    isSetPwd(czOrTx) {
      isSet()
          .then(res => {
            if (res.code === 200) {
              if (res.data) {
                Modal.confirm({
                  title: '提醒',
                  centered: true,
                  content: '您未设置支付密码,前去设置?',
                  onOk: () => {
                    this.$router.push({ path: '/AccountSafe' })
                  },
                  onCancel: () => {
                  }
                })
              } else {
                this.queryRate()
                this.userBalance()
                this.formData = {
                  amount: 10,
                  rate: '',
                  balance: 0,
                  factAmount: 0,
                  payPassword: '',
                }
                if (czOrTx === 'cz') {
                  this.xYVisibleFlag = 1
                }
                if (czOrTx === 'tx') {
                  this.userBalance()
                  this.xYVisibleFlag = 2
                }
                this.xYVisible = true
              }
            }
          })
    },
    queryCardBackInfo() {
      this.loadingBack = true
      cardBackInfo()
          .then(res => {
            if (res.code === 200) {
              this.cardBackInfo = res.data
              if (this.cardBackInfo) {
                this.cardBackInfo.cardNo = this.cardBackInfo.cardNo.replace(/(.{4})(?=.)/g, '$1 ')
                    .trim()
                this.cardBackInfo.expYear = this.cardBackInfo.expYear.slice(-2)
              }
              this.selectIndex = 1
              this.loadingBack = false
            } else {
              this.loadingBack = false
            }
          })
    },
    copy() {
      let text = this.cardBackInfo.cardNo.replace(/\s+/g, '') + ' ' + this.cardBackInfo.firstName + ' ' + this.cardBackInfo.lastName + ' ' + this.cardBackInfo.expMonth + '/' + this.cardBackInfo.expYear + ' ' + this.cardBackInfo.cvv
      this.$Copy({ text: text })
    },
    checkLetter() {
      this.cardData.firstName = this.cardData.firstName.replace(/[^a-zA-Z]/g, '')
    },
    checkLetter1() {
      this.cardData.lastName = this.cardData.lastName.replace(/[^a-zA-Z]/g, '')
    },
    onFinish() {
      if (!this.cardData.phone) {
        this.showClearIconPhone = false
        this.phoneHelp = '请输入手机号'
        return
      }
      if (!this.showClearIconPhone) {
        this.phoneHelp = '请输入正确手机号'
        return
      }
      this.checkPhoneSearch()
      this.phoneHelp = ''
      create(this.cardData)
          .then(res => {
            if (res.code === 200) {
              this.$Message.success('申请已提交,请稍后可否申请!!!')
              this.cardIsCreate()
            }
          })
    },
    changeCountry(event) {
      this.cardData.iso2 = event.iso2
      this.cardData.countryCode = event.dialCode
      this.checkPhoneSearch()
    },
    checkPhoneSearch() {
      // 检测手机是否已注册
      let data = {
        phone: this.cardData.phone,
        countryCode: this.cardData.countryCode
      }
      checkPhone(data)
          .then(res => {
            if (res.code === 200) {
              this.showClearIconPhone = true
              this.phoneHelp = ''
            } else {
              this.showClearIconPhone = false
              this.phoneHelp = '手机号不正确'
            }
          })
          .catch(res => {
            this.showClearIconPhone = false
            this.phoneHelp = '手机号不正确'
          })
    },
    // 手机键盘输入实时反馈
    onKeyInputPhone: function (event) {
      if (!this.cardData.phone) {
        this.showClearIconPhone = false
        this.phoneHelp = '请输入手机号'
        return
      }
      this.checkPhoneSearch()
    },
    cardIsCreate() {
      isCreate()
          .then(res => {
            if (res.code === 200) {
              if (res.data) {
                this.auditStatus = res.data.auditStatus
                this.isCreated = true
                this.cardData = res.data
                if (this.cardData.auditStatus === 1) {
                  this.queryTransList()
                  setTimeout(() => {
                    this.queryCardInfo()
                  }, 1000)
                }
              } else {
                this.isCreated = false
              }
              if (this.auditStatus === 0 || this.auditStatus === -1 || this.auditStatus === 2) {
                this.selectIndex = 2
              }
            }
          })
    },
    onComplete(e) {
      this.payVisible = false
      checkPaypwd(e)
          .then(res => {
            if (res.code === 200) {
              this.queryCardBackInfo()
            } else {
              this.$Message.error('支付密码不正确,请重新输入')
            }
          })
    },
    queryCardInfo() {
      cardInfo()
          .then(res => {
            if (res.code === 200) {
              this.cardInfo = res.data
              this.amount = this.cardInfo.balance
            }
          })
    },
    queryTransList() {
      this.loading = true
      let param = {
        page: this.page,
        limit: this.limit
      }
      cardTransactions(param)
          .then(res => {
            if (res.code === 200) {
              this.total = res.data.total
              this.transferList = res.data.list
              this.loading = false
            } else {
              this.loading = false
            }
          })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.page = val
      this.queryTransList()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.page = 1
      this.limit = val
      this.queryTransList()
    },
    tab(selectedIndex) {
      if (selectedIndex === 1) {
        isSet()
            .then(res => {
              if (res.code === 200) {
                if (res.data) {
                  Modal.confirm({
                    title: '提醒',
                    centered: true,
                    content: '您未设置支付密码,前去设置?',
                    onOk: () => {
                      this.$router.push({ path: '/AccountSafe' })
                    },
                    onCancel: () => {
                    }
                  })
                } else {
                  // 打开支付密码
                  this.payVisible = true
                }
              }
            })
      } else {
        this.selectIndex = selectedIndex
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input-cell {
  width: 100% !important;
  height: 36px !important;
  box-shadow: 0px 3px 10px 0px rgba(107, 131, 200, 0.08);
  border-radius: 10px;
  border: 1px solid #E9EAEB;
}

.from-btn {
  width: 260px;
  height: 60px;
  background: linear-gradient(-90deg, #0080D9, #0052D9);
  box-shadow: 0px 5px 16px 0px rgba(71, 100, 184, 0.3);
  border-radius: 10px;

  font-weight: 800;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 38px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-150 {
  margin-left: 60px;
}

.input-cell {
  height: 48px;
  box-shadow: 0px 3px 10px 0px rgba(107, 131, 200, 0.08);
  border-radius: 10px;
  border: 1px solid #E9EAEB;
}

.top-pane-0 {
  width: 300px;
  height: 132px;
  border-radius: 13px;

  font-weight: 400;
  font-size: 16px;
  color: #1971b6;
  line-height: 34px;
  background-color: #f7fafa;

  padding: 24px;
}

.top-pane-1 {
  width: 250px;
  height: 60px;
  border-radius: 13px;

  font-size: 16px;
  color: #1971b6;
  line-height: 34px;
  background-color: #f7fafa;

  padding: 10px;
}

.usdt-amount {
  font-weight: 500;
  font-size: 16px;
  color: #1971b6;
  line-height: 34px;
}

.usdt-amount-button-copy {
  width: 110px;
  height: 32px;
  border-radius: 5px;
  background-color: #E9F4FE;

  font-weight: 400;
  font-size: 16px;
  color: #299DF4;
  line-height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.usdt-amount-button-yj {
  height: 32px;
  border-radius: 5px;
  background-color: #ECFCE4;

  font-weight: 400;
  font-size: 16px;
  color: #2B9A21;
  line-height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;
}

.golbal-btn-cz-tx:hover {
  font-weight: bold !important;
}

.usdt-amount-button-cz {
  width: 90px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #00C853;

  font-weight: 400;
  font-size: 16px;
  color: #00C853;
  line-height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.usdt-amount-button-tx {
  width: 90px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #e23a3a;

  font-weight: 400;
  font-size: 16px;
  color: #e23a3a;
  line-height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.usdt-amount-button-create {
  font-weight: 600;
  border: 1px solid #FB3A5C !important;
  color: #FB3A5C !important;;
}

.usdt-amount-button-create:hover {
  font-weight: bold !important;
  color: #FB3A5C !important;
}

.usdt-amount-button {
  margin-top: 2px;
  width: 90px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #1971b6;

  font-weight: 400;
  font-size: 14px;
  color: #1971b6;
  line-height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.tab_font {
  color: #FFFFFF !important;
  background-color: #1971b6 !important;
  font-weight: bold !important;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
