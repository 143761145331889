<template>
  <div class="home-home-container">
    <div class="zouma">
      <div class="d-flex shadow">
        <div class="cu-center" style="width: 100%;">
          <arco-carousel style="height: 480px; width:100%;background-color: #FFFFFF;" indicator-position="bottom"
                         :dots="true" :autoPlay="true" show-arrow="never">
            <arco-carousel-item class="car-item cursor-pointer" @click="toGuarantee">
              <a-image :preview="false" :height="480" :src="require('@/assets/new/home/banner.png')"
                       style="object-fit: cover;"></a-image>
            </arco-carousel-item>
            <arco-carousel-item class="car-item cursor-pointer" @click="toGuarantee">
              <a-image :preview="false" :height="480" :src="require('@/assets/new/home/banner.png')"
                       style="object-fit: cover;"></a-image>
            </arco-carousel-item>
            <arco-carousel-item class="car-item cursor-pointer" @click="toGuarantee">
              <a-image :preview="false" :height="480" :src="require('@/assets/new/home/banner.png')"
                       style="object-fit: cover;"></a-image>
            </arco-carousel-item>
          </arco-carousel>
        </div>
      </div>
      <div class="cu-center" style="width: 100%;">
        <a-row :gutter="[16,24]" class="el-row-top">
          <a-col :span="6" class="cu-center el-col-top">
            <div class="flex justify-center align-center padding-left-sm">
              <a-image :width="110" :height="80" :preview="false" :src="require('@/assets/new/home/top_0.png')"/>
              <div class="margin-left-xss">
                <div class="top-font-title">安全可靠</div>
                <div class="top-font-info margin-bottom-xs">保障交易资金安全</div>
              </div>
            </div>
          </a-col>
          <a-col :span="6" class="cu-center el-col-top">
            <div class="flex justify-center align-center padding-left-sm">
              <a-image :width="102" :height="82" :preview="false" :src="require('@/assets/new/home/top_1.png')"/>
              <div class="margin-left-xss">
                <div class="top-font-title">提升交易效率</div>
                <div class="top-font-info margin-bottom-xs">加速交易，减少等待</div>
              </div>
            </div>
          </a-col>
          <a-col :span="6" class="cu-center el-col-top">
            <div class="flex justify-center align-center padding-left-sm">
              <a-image :width="67" :height="66" :preview="false" :src="require('@/assets/new/home/top_2.png')"/>
              <div class="margin-left-xs">
                <div class="top-font-title">匿名性与隐私保护</div>
                <div class="top-font-info margin-bottom-xs">匿名交易，隐私守护</div>
              </div>
            </div>
          </a-col>
          <a-col :span="6" class="cu-center el-col-top">
            <div class="flex justify-center align-center padding-left-sm">
              <a-image :width="97" :height="80" :preview="false" :src="require('@/assets/new/home/top_3.png')"/>
              <div class="margin-left-xss">
                <div class="top-font-title">广泛交易类型</div>
                <div class="top-font-info margin-bottom-xs">满足不同交易形式的需求</div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>

    <div class="model-content-one">
      <!--  人气精选    -->
      <div class="width_1200_auto mt-12">
        <div class="danbao-content mb-6">
          <div class="danbao-content-top">
            <div class="top-font">
              人气精选
              <span class="fu-title">口碑之选，值得信赖！</span>
            </div>
            <div class="top-font-right cursor-pointer" @click="toGoodsList">
              {{ $t('i_010') }}
              <Icon :size="16" type="ios-arrow-forward"/>
            </div>
          </div>
        </div>
        <a-row class="margin-top-sm" :gutter="[20,24]">
          <a-col class="mer-card" v-if="loading" :span="6" v-for="index in 8" :key="index">
            <a-skeleton active :loading="loading" avatar :paragraph="{ rows: 4 }"/>
          </a-col>
          <a-col v-if="!loading" :span="6" v-for="(item, index) in merList" :key="index">
            <a-card class="hover-item-up" :hoverable="true" :loading="loading" :bordered="false"
                    :bodyStyle="{padding: '20px 25px', height: '104px'}">
              <div class="mer-card-content">
                <div class="mer-card-title cursor-pointer"
                     @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}">
                  <div style="width: 100%" class="flex flex-direction justify-between align-center">
                    <div class="d-flex justify-space-between" style="width: 100%">
                      <div style="max-width: 220px;">
                        <div class="mer-name line1">{{ item.name }}</div>
                        <div class="text-gray text-sm mt-1 line1">{{ item.intro }}</div>
                      </div>
                    </div>
                    <div class="custom-font" style="width: 100%">{{ $t('i_011') }}{{ item.earnestMoney }} USDT</div>
                  </div>
                  <div class="avatar">
                    <a-avatar style="border: 1px solid #e8e8e8" :src="item.avatar" :size="64"></a-avatar>
                  </div>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <!--  口碑推荐    -->
      <div class="width_1200_auto mt-12">
        <div class="danbao-content mb-6">
          <div class="danbao-content-top">
            <div class="top-font">
              口碑推荐
              <span class="fu-title">用心甄选，每件都值得信赖！</span>
            </div>
            <div class="top-font-right cursor-pointer" @click="toGoodsList">
              {{ $t('i_010') }}
              <Icon :size="16" type="ios-arrow-forward"/>
            </div>
          </div>
        </div>
        <a-row class="margin-top-sm" :gutter="[21,24]">
          <a-col class="mer-card" :span="4" v-for="(item, index) in merListTwo" :key="index">
            <a-card class="hover-item-up" :hoverable="true" :loading="loadingTwo" :bordered="false"
                    :bodyStyle="{padding: '0'}">
              <div class="position-relative cursor-pointer">
                <div class="gradient-background">
                  <a-image :preview="false"
                           style="border-top-left-radius: 20px;border-top-right-radius: 20px;"
                           :src="item.avatar" width="239.16px" height="249.16px"
                           class="img-fit"
                           @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}"></a-image>
                </div>
                <div class="position-absolute cursor-pointer" style="left: 10px; bottom: -30px;">
                  <div class="flex" @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}">
                    <a-avatar style="border: 1px solid #e8e8e8" :src="item.avatar" :size="64"></a-avatar>
                  </div>
                </div>
                <div class="position-absolute cursor-pointer" style="left: 80px; bottom: -30px;"
                     @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}">
                  <div class="text-lg line1 text-bold" style="max-width: 320px;">
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="mer-card-content" style="border-top: 1px solid #e8e8e8;"
                   @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}">
                <div class="mer-card-title cursor-pointer padding-top padding-left-xs">
                  <div class="custom-font" style="width: 100%">{{ $t('i_011') }}{{ item.earnestMoney }} USDT</div>
                </div>
                <div class="mer-card-title cursor-pointer padding-left-xs">
                  <div class="custom-font-cus line2 padding-right-xs">
                    {{ item.intro }}
                  </div>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="model-content-all mt-8">
      <!--   口碑专群   -->
      <div class="margin-top-xs">
        <div class="position-relative">
          <a-image :preview="false" :src="require('@/assets/new/home/major_banner.png')"/>
          <div class="width_1200_auto flex justify-between position-absolute" style="left: 161px;top: 0;">
            <div class="zhuanqun cursor-pointer padding-top" @click="toGuarantee">
              <div>51专群</div>
              <div class="zhuanqun-info">
                轻松发起专属群担保，安全透明，保障双方权益。让每一笔交易更放心、更便捷！
              </div>
            </div>
            <div class="zhuanqun danbao-content" style="margin-top: -5px !important;">
              <div class="danbao-list flex">
                <div class="danbao-cell" v-show="index <= 1" :class="index > 0 ? 'margin-left-lg' : ''"
                     v-for="(item, index) in cusList" :key="index">
                  <List>
                    <ListItem>
                      <ListItemMeta description="">
                        <template #avatar>
                          <a-avatar :size="80" :src="item.headImg"/>
                        </template>
                        <template #title>
                          <div class="danbao-cell-title">{{ item.userName }}</div>
                        </template>
                        <template #description>
                          <div class="danbao-cell-btns mt-3">
                            <div class="mr-4 danbao-cell-btn1 cursor-pointer gr-btn" @click="openLink(item.tgUrl)">
                              <a-image :preview="false" :src="require('@/assets/new/home/msg.png')" :width="24"/>
                              <span class="margin-left-xs">{{ $t('i_013') }}</span>
                            </div>
                            <div class="danbao-cell-btn2 cursor-pointer gr-btn" @click="sendTg(item.typeId)">
                              {{ $t('i_014') }}
                            </div>
                          </div>
                        </template>
                      </ListItemMeta>
                    </ListItem>
                  </List>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  新店推荐-其他   -->
      <div class="padding-top-xl mb-5 bg-white">
        <div class="width_1200_auto" style="height: 432px;">
          <div class="danbao-content">
            <div class="danbao-content-top">
              <div class="top-font">
                新店推荐
                <span class="fu-title">快速发现优质商家</span>
              </div>
              <div class="top-font-right cursor-pointer">
                <!--                <Space>-->
                <!--                  <div class="back-button" @click="shopIndexLeft">-->
                <!--                    <LeftOutlined/>-->
                <!--                  </div>-->
                <!--                  <div class="forward-button" @click="shopIndexRight">-->
                <!--                    <RightOutlined/>-->
                <!--                  </div>-->
                <!--                </Space>-->
              </div>
            </div>
          </div>
          <a-row v-if="loadingThree" class="mt-5" :gutter="[19,24]">
            <a-col class="mer-card" :span="4" v-for="index in 5" :key="index">
              <a-skeleton active :loading="loadingThree" avatar :paragraph="{ rows: 4 }"/>
            </a-col>
          </a-row>
          <arco-carousel v-else v-model:current="currentIndex"
                         :default-current="1"
                         style="height: 384px;width:100%;"
                         :indicatorType="false"
                         :autoPlay="true"
                         @change="change">
            <arco-carousel-item v-for="(itemALL, index) in merListThreeChunArray" :key="index">
              <a-row class="mt-5 pl-1 pr-1" :gutter="[19,24]">
                <a-col class="mer-card" :span="4" v-for="(item, itemIndex) in itemALL" :key="itemIndex">
                  <a-card class="new-store hover-item-up-other"
                          :hoverable="true"
                          :loading="loadingThree"
                          :bordered="false"
                          :bodyStyle="{padding: '0'}"
                          @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}">
                    <div class="position-relative cursor-pointer">
                      <div class="position-absolute cursor-pointer" style="left: 20px; top: -26px;">
                        <a-image v-if="activeKey === 5 || activeKey === 0" :preview="false"
                                 :src="require('@/assets/new/home/new.png')" width="34px" height="36px"/>
                        <a-image v-if="activeKey === 2" :preview="false" :src="require('@/assets/index/hot3.png')"
                                 width="34px" height="36px"/>
                        <a-image v-if="activeKey === 3" :preview="false" :src="require('@/assets/index/hot4.png')"
                                 width="34px" height="36px"/>
                        <a-image v-if="activeKey === 4" :preview="false" :src="require('@/assets/index/hot5.png')"
                                 width="37px" height="36px"/>
                      </div>
                      <div class="flex align-center justify-center mt-6">
                        <a-avatar style="border: 1px solid #e8e8e8" :src="item.avatar" :size="120"></a-avatar>
                      </div>
                      <div class="cursor-pointer flex align-center justify-center mt-6">
                        <div class="mer-name line1 text-bold text-lg">
                          {{ item.name }}
                        </div>
                      </div>
                      <div class="cursor-pointer flex align-center justify-center mt-4">
                        <div class="custom-font-cus line2 padding-lr-xs">
                          {{ item.intro }}
                        </div>
                      </div>
                      <div class="cursor-pointer flex align-center justify-center mt-4">
                        <div class="custom-money-cus">{{ $t('i_011') }}{{ item.earnestMoney }} USDT</div>
                      </div>
                    </div>
                  </a-card>
                </a-col>
              </a-row>
            </arco-carousel-item>
          </arco-carousel>
        </div>
      </div>
      <!--  新品推荐  -->
      <div class="model-content-all mb-5 mt-3">
        <div class="width_1200_auto padding-top">
          <div class="danbao-content">
            <div class="danbao-content-top">
              <div class="top-font">
                新品推荐
              </div>
              <div class="top-font-right cursor-pointer" @click="toGoodsProductList">
                {{ $t('i_010') }}
                <Icon :size="16" type="ios-arrow-forward"/>
              </div>
            </div>
            <div class="margin-top-sm">
              <div class="container-product-list">
                <a-card :hoverable="true" :bordered="false" :bodyStyle="{padding: '0'}"
                        class="box cursor-pointer gongxu-item-cus hover-item-up"
                        v-for="(item, index) in hotProducts" :key="index"
                        :loading="hotProductLoading"
                        @click="toProduct(item)">
                  <div class="goods-show-img">
                    <img :preview="false" class="goods-show-img" :width="280" :height="280" :src="item.image"/>
                  </div>
                  <div class="padding-lr-xs">
                    <div class="goods-show-detail">
                      {{ item.name }}
                    </div>
                    <div class="goods-show-price text-danger">
                      {{ item.price }} USDT
                    </div>
                    <div class="goods-show-num">
                      <div>{{ item.sales || 0 }} 人付款</div>
                      <div class="padding-lr-xs">|</div>
                      <div>{{ item.merName }}</div>
                    </div>
                  </div>
                </a-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="model-content-all mt-2">
      <!--  供需  -->
      <div class="width_1200_auto padding-top-sm">
        <div class="danbao-content">
          <div class="danbao-content-top">
            <div class="top-font">
              供需
            </div>
            <div class="top-font-right cursor-pointer" @click="toGongxu">
              {{ $t('i_010') }}
              <Icon :size="16" type="ios-arrow-forward"/>
            </div>
          </div>
          <div class="margin-top-sm">
            <a-row :gutter="[16,24]">
              <a-col :span="6" v-for="(item, index) in noteRecommendList" :key="index" @click="toGongxuInfo(item)">
                <a-card :hoverable="true" :bordered="false" :bodyStyle="{padding: '20px'}" class="position-relative
                    cursor-pointer gongxu-item-cus hover-item-up" :loading="noteRecommendLoading">
                  <div class="position-absolute" style="right: 0; top: 0;">
                    <a-image v-if="item.categoryId === 1" :preview="false" :src="require('@/assets/new/home/gong.png')"
                             width="53px" height="66px"/>
                    <a-image v-if="item.categoryId === 2" :preview="false" :src="require('@/assets/new/home/xu.png')"
                             width="53px" height="66px"/>
                  </div>
                  <div class="position-absolute" style="left: 20px; top: 20px;z-index: 1;">
                    <a-image :preview="false" :src="require('@/assets/new/home/yinhao.png')" width="48px"
                             height="40px"/>
                  </div>
                  <div class="position-absolute padding-right-xs" style="left: 38px; top: 30px;z-index: 10;">
                    <div class="text-bold text-lg text-cut mt-2">{{ item.title }}</div>
                    <div class="text-gray line2 mt-2">{{ item.content }}</div>
                  </div>
                </a-card>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
      <!--  热点资讯  -->
      <div class="width_1200_auto padding-top-xl mt-3 padding-bottom-lg">
        <div class="danbao-content">
          <div class="danbao-content-top">
            <div class="top-font">
              热点资讯
            </div>
            <div class="top-font-right cursor-pointer" @click="toInfors">
              {{ $t('i_010') }}
              <Icon :size="16" type="ios-arrow-forward"/>
            </div>
          </div>
          <div class="margin-top-sm">
            <a-row :gutter="[16,24]">
              <a-col :span="12" v-for="(item, index) in inforList" :key="index" @click="toInforDetails(item)">
                <a-card :hoverable="true" :bordered="false" :bodyStyle="{padding: '20px'}"
                        :loading="infoLoading"
                        class="position-relative cursor-pointer info-item-cus hover-item-up">
                  <div class="position-absolute" style="left: 0; top: 28px;">
                    <a-image :preview="false" :src="require('@/assets/new/home/hotInfo.png')" width="10px"
                             height="18px"/>
                  </div>
                  <div class="text-bold text-lg text-cut mt-2">
                    {{ getTextBeforeFirstPunctuation(item.contentInfo) }}
                  </div>
                  <div class="text-gray line4 mt-2">{{ item.contentInfo }}</div>

                  <div class="position-absolute flex justify-between align-center"
                       style="left: 20px; bottom: 20px;width: 100%">
                    <div class="text-gray">
                      <a-avatar :src="item.avatar" :size="20"></a-avatar>
                      <span>{{ item.nickname }}</span>
                      <span>{{ item.createTime }}</span>
                    </div>
                    <div class="padding-right-lg">
                      <a-image :preview="false" :src="require('@/assets/new/home/jiantou.png')" :width="42"
                               :height="4"/>
                    </div>
                  </div>
                </a-card>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
    <to-trx :visible="toTrxViable" @close="toTrxClose"/>
  </div>
</template>

<script>
import Search from '@/components/Search.vue'
import cateNav from '@/components/nav/CateNav.vue'
import toTrx from '@/components/toTrx/index.vue'
import CustomerService from '@/components/customerService/index.vue'

import { getArticleList } from '@/api/api'
import { getDiyMerListApi, getDiyMerListIndexApi, getDiyMerListReverApi } from '@/api/merchant'
import { getProductHotNew } from '@/api/product'

import { isFreeRandomOne, queryCusList } from '@/api/tg.js'
import { discoverListApi } from '@/api/discover.js'
import { LeftOutlined, RightOutlined, SmileTwoTone } from '@ant-design/icons-vue'

import { inforList, } from '@/api/infor.js'
import { Space } from 'view-ui-plus'
import { Vibrant } from 'node-vibrant/browser'

export default {
  name: 'HomePage',
  components: {
    Space,
    SmileTwoTone,
    Search,
    cateNav,
    toTrx,
    CustomerService,
    LeftOutlined,
    RightOutlined
  },
  data() {
    return {
      userInfo: {}, // 用户信息
      dataMsg: [],
      activeKey: 0,
      activeGxKey: '1',
      merList: [],
      numConfig: 8,
      loading: false,
      cusList: [],
      loadingSke: false,
      noteRecommendList: [],
      page: 1,
      limit: 4,
      total: 0,
      inforList: [],
      imgs: [],
      hots: [],
      toTrxViable: false,
      merListTwo: [],
      loadingTwo: false,
      numThreeConfig: 6,
      merListThree: [],
      merListThreeChunArray: [],
      loadingThree: false,
      shopIndex: 0,
      leftOrRight: false,
      currentIndex: 1,
      hotProducts: [],
      hotProductLoading: false,
      noteRecommendLoading: false,
      infoLoading: false,
      merBackColor: [],
      merBackMap: [],
    }
  },
  watch: {
    '$store.getters.token': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (!newVal) {
            this.userInfo = { nickname: '' }
          }
        })
      }
    }
  },
  created() {
    this.getMerList()
    this.getMerListTwoByOrderNo()
    this.getMerListRever()
    this.queryProductHotNew()

    this.getDbCusList()
    this.getDiscoverList()
    this.infos()
    this.infoHots()

    let user = localStorage.getItem('USER_INFO')
    if (user) {
      this.userInfo = JSON.parse(user)
    }
  },
  methods: {
    async getDominantColor(imageUrl) {
      const vibrant = new Vibrant(imageUrl)
      const palette = await vibrant.getPalette()
      // 找出占比最多的颜色
      let dominantColor = null
      let maxPopulation = 0
      // 遍历色板，找出 population 最大的颜色
      for (let swatchKey in palette) {
        const swatch = palette[swatchKey]
        if (swatch) {
          if (swatch.population > maxPopulation) {
            maxPopulation = swatch.population
            dominantColor = swatch.hex
          }
        }
      }
      return dominantColor
    },
    queryProductHotNew() {
      let param = {
        page: 1,
        limit: 50
      }
      this.hotProductLoading = true
      getProductHotNew(param)
          .then(res => {
            this.hotProducts = res.data.list
            this.hotProductLoading = false
          })
    },
    chunkArray(array, size) {
      const result = []
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size))
      }
      return result
    },
    getTextBeforeFirstPunctuation(str) {
      // 匹配中英文标点符号前的内容
      const match = str.match(/^[^，。！？；：“”‘’（）【】《》「」,.!?;:"'()\[\]<> ]+/)
      return match ? match[0] : ''
    },
    toTrxClose() {
      this.toTrxViable = false
    },
    toInforDetails(item) {
      this.$router.push({
        path: '/Details',
        query: { inforId: item.id }
      })
    },
    infos() {
      let data = {
        page: this.page,
        limit: this.limit,
        itemId: 1
      }
      this.infoLoading = true
      inforList(data)
          .then((res) => {
            let curPageData = res.data.list
            this.total = res.data.total
            this.inforList = curPageData //追加新数据
            this.inforList.forEach(item => {
              if (item.images) {
                item.img = item.images.split(',')
                if (item.img && item.img.length > 0) {
                  if (this.imgs.length <= 3) {
                    this.imgs.push({
                      id: item.id,
                      url: item.img[0]
                    })
                  }
                }
              }
            })
            this.infoLoading = false
          })
    },
    infoHots() {
      let data = {
        page: 1,
        limit: 3,
        itemId: 0
      }
      inforList(data)
          .then((res) => {
            this.hots = res.data.list
            this.hots.forEach(item => {
              if (item.images) {
                item.img = item.images.split(',')
              }
            })
          })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.page = val
      this.infos()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.page = 1
      this.limit = val
      this.infos()
    },
    toInfors() {
      this.$router.push({
        path: '/Infor'
      })
    },
    toGoodsList() {
      this.$router.push({
        path: '/GoodsList',
        query: {
          keyword: '',
          selectFlag: '1',
        },
      })
    },
    toGoodsProductList() {
      this.$router.push({
        path: '/GoodsList',
        query: {
          keyword: '',
          selectFlag: '0',
        },
      })
    },
    sendTg(typeId) {
      if (this.$store.getters.token) {
        this.getRandomOneQuery(typeId)
      } else {
        this.$router.push({
          path: '/Login',
          query: {}
        })
      }
    },
    getRandomOneQuery(typeId) {
      isFreeRandomOne(typeId)
          .then((res) => {
            if (res.code === 200) {
              this.$router.push({
                path: '/Guarantee',
                query: {
                  id: typeId,
                }
              })
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    toGuarantee() {
      this.$router.push({
        path: '/Guarantee',
        query: {}
      })
    },
    openLink(url) {
      if (this.$store.getters.token) {
        window.open(url)
      } else {
        this.$router.push({
          path: '/Login',
          query: {}
        })
      }
    },
    toGongxu() {
      this.$router.push({
        name: 'Supply',
        query: {}
      })
    },
    toGongxuInfo(item) {
      this.$router.push({
        path: '/SupplyInfo',
        query: {
          id: item.id,
        }
      })
    },
    toUserInfo() {
      this.$router.push({
        name: 'UserHome',
        query: {}
      })
    },
    //发现列表
    getDiscoverList() {
      this.where = {
        page: 1,
        limit: 8
      }
      this.noteRecommendLoading = true
      discoverListApi(this.where)
          .then(res => {
            this.noteRecommendList = res.data.list
            this.noteRecommendLoading = false
          })
    },
    getDbCusList() {
      let data = {
        page: 1,
        limit: 12
      }
      this.loadingSke = true
      queryCusList(data)
          .then((res) => {
            this.cusList = res.data.list
            this.loadingSke = false
          })
    },
    load({ done }) {
      done('empty')
    },
    changeGxActive(event) {
      this.activeGxKey = event
      this.getDiscoverList()
    },
    change(index, prevIndex) {
      this.currentIndex = index
    },
    shopIndexLeft() {
      let number = this.currentIndex - 1
      if (number <= 0) {
        this.currentIndex = this.merListThreeChunArray.length
      } else {
        this.currentIndex = number
      }
    },
    shopIndexRight() {
      let number = this.currentIndex + 1
      if (number > this.merListThreeChunArray.length) {
        this.currentIndex = 1
      } else {
        this.currentIndex = number
      }
    },
    changeActive(event) {
      this.activeKey = event
      if (event === 0) {
        this.getMerList()
      }
      if (event >= 1 && event <= 4) {
        this.getMerListByOrderNo(event)
      }
      if (event === 5) {
        this.getMerListRever()
      }
    },
    getMerListTwoByOrderNo() {
      this.loadingTwo = true
      let data = {
        num: 12,
        order: 1
      }
      this.merBackColor = []
      getDiyMerListIndexApi(data)
          .then(async (res) => {
            this.merListTwo = res.data.list
            this.loadingTwo = false
            for (const item of this.merListTwo) {
              const index = this.merListTwo.indexOf(item)
              // this.merBackColor.splice(index, 0, dominantColor)
              const dominantColor = await this.getDominantColor(item.avatar, index)
              this.merBackColor.splice(index, 0, dominantColor)
            }
          })
    },
    getMerListByOrderNo(event) {
      this.loadingThree = true
      let data = {
        num: 6,
        order: event
      }
      getDiyMerListIndexApi(data)
          .then((res) => {
            this.merListThree = res.data.list
            this.loadingThree = false
          })
    },
    //默认店铺数据
    getMerList() {
      this.loading = true
      getDiyMerListApi(this.numConfig)
          .then((res) => {
            this.merList = res.data.list
            this.loading = false
          })
    },
    getMerListRever() {
      this.loadingThree = true
      getDiyMerListReverApi(100)
          .then((res) => {
            console.log(res)
            this.merListThree = res.data.list
            this.merListThreeChunArray = []
            this.merListThreeChunArray = this.chunkArray(this.merListThree, 6)

            this.loadingThree = false
          })
    },
    getCidArticle() {
      const data1 = {
        page: 1,
        limit: 1000
      }
      getArticleList(1, data1)
          .then(res => {
            this.dataMsg = res.data.list
          })
    },
    toProduct(item) {
      this.$router.push({
        path: '/Product',
        query: {
          id: item.id,
        }
      })
    },
    toLogin() {
      this.$router.push({
        path: '/login',
        query: {}
      })
    },
    toRegister() {
      this.$router.push({
        path: '/signUp',
        query: {}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
:deep(.arco-carousel-arrow > div:hover) {
  background: #2b85e4 !important;
  border: 1px solid #2b85e4 !important;

  :deep(.arco-carousel-arrow > div > svg) {
    color: #EEEEEE !important;
  }
}

:deep(.arco-carousel-arrow > div > svg:hover) {
  color: #FFFFFF !important;
}

:deep(.arco-carousel-arrow > div) {
  width: 40px !important;
  height: 40px !important;
  background: #FFFFFF !important;
  border: 1px solid #e9e9e9 !important;
}

:deep(.arco-carousel-arrow > div > svg) {
  color: #EEEEEE !important;
  font-size: 24px !important;
}

:deep(.arco-carousel-arrow-left) {
  top: -5% !important;
  right: 50px !important;
  left: 94.5% !important;
}

:deep(.arco-carousel-arrow-right) {
  top: -5% !important;
  right: 0 !important;
}

.gradient-background {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.goods-show-img {
  border-radius: 10px;
}

.goods-show-num {
  margin-top: 5px;
  font-size: 14px;
  color: #6B6C6D;
  display: flex;
}

.goods-show-num span {
  color: #666;
  font-weight: bold;
}

.goods-show-seller {
  font-size: 12px;
  color: #808080;
}

.text-danger {
  color: #F31947;
}

.goods-show-detail {
  height: 46px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
  overflow: hidden;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.goods-show-price {
  margin-top: 6px;
  font-weight: bold;
  font-size: 20px;
  line-height: 34px;
}

.goods-show-detail:hover {
  color: #E23A3A;
}


.container-product-list {
  width: 1600px;
  display: flex;
  flex-wrap: wrap; /* 自动换行 */
  gap: 10px; /* 元素间距 */

  .box {
    flex: 0 0 calc(20% - 10px); /* 每行 5 个 (20%)，减去间距 */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    width: 304px;
    height: 432px;
    padding: 12px;
    position: relative;
    cursor: pointer;
  }
}

.gr-btn:hover {
  transform: scale(1.1);
}

.slide-left {
  animation: slide-left-cus 2s forwards;
  will-change: transform; /* 提示浏览器优化 */
}

.slide-right {
  animation: slide-right-cus 2s forwards;
  will-change: transform; /* 提示浏览器优化 */
}

@keyframes slide-left-cus {
  0% {
    opacity: 0;
    transform: translateX(-100%)
  }
  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

@keyframes slide-right-cus {
  0% {
    opacity: 0;
    transform: translateX(100%)
  }
  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

.customer-service {
  position: fixed;
  bottom: 20px; /* 距离底部 20px */
  right: 20px; /* 距离右侧 20px */
  width: 90px; /* 按钮宽度 */
  height: 90px; /* 按钮高度 */
  background-color: #3B64DA; /* 按钮背景色 */
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 20px 0px rgba(107, 131, 200, 0.06);
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.customer-service:hover {
  transform: scale(1.1); /* 鼠标悬浮时放大 */
  background-color: #3B64DA; /* 悬浮时改变背景色 */
  color: #3A69FB;
}

.customer-service img {
  width: 50%; /* 图标大小 */
  height: 50%;
  object-fit: contain;
}

.info-item-cus {
  height: 208px;
  box-shadow: 0px 5px 20px 0px rgba(107, 131, 200, 0.06);
  border-radius: 20px;
}

.back-button {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #e8e8e8;

  display: flex;
  justify-content: center;
  align-items: center;
}

.back-button:hover {
  background-color: #2b85e4;
  color: #fff;
}

.forward-button {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #e8e8e8;

  display: flex;
  justify-content: center;
  align-items: center;
}

.forward-button:hover {
  background-color: #2b85e4;
  color: #fff;
}

.custom-money-cus {
  height: 32px;
  padding: 0 36px;
  background: #FCF3EB;
  border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  color: #FF6E26;
  line-height: 20px;
}

.new-store {
  height: 324px;
  background: linear-gradient(0deg, #F3F7FB, #FFFFFF);
  box-shadow: 0px 5px 20px 0px rgba(107, 131, 200, 0.13);
  border-radius: 20px;
  border: 2px solid #FFFFFF;
  position: relative;
}

.a-card:hover {
  box-shadow: 0px 0px 10px #D8F2FC;
}

.zhuanqun {
  font-weight: bold;
  font-size: 21px;
  color: #FFFFFF;
  line-height: 34px;

  .zhuanqun-info {
    font-weight: 500;
    font-size: 15px;
    color: #CFD6E0;
  }
}

.fu-title {
  font-weight: 400;
  font-size: 16px;
  color: #6B6C6D;
  line-height: 20px;
  margin-left: 15px;
}

.el-row-top {
  width: 1920px;
  height: 140px;
  background-color: #FFFFFF;
  margin: 0 auto;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.el-col-top {
  height: 140px;
  margin: 0 auto;
}

.top-font-title {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 32px;
}

.top-font-info {
  font-weight: 400;
  font-size: 16px;
  color: #6B6C6D;
  line-height: 32px;
}

:deep(.arco-carousel-indicator-bottom) {
  bottom: auto !important;
}

:deep(.arco-carousel-indicator-wrapper-bottom) {
  height: 0 !important;
  bottom: 20px !important;
}

.shadow-cus {
  box-shadow: 0px 4px 10px 0px rgba(0, 6, 0, 0.3);
  border-radius: 5px;
}

.hover-color-cus:hover {
  color: #2b85e4 !important;
  cursor: pointer;
}

.img-fit-cus {
  object-fit: cover !important;
}

.right-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 0px;
  color: #3D3D3D;
}

.zouma {
  position: relative;
  height: 420px;

  .car-right {
    position: absolute;
    width: 100%;
    height: 420px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .car-item {
      display: flex;
      justify-content: end;
      height: 420px;
      border-radius: 10px;
    }
  }
}

:deep(.slick-slide) {
  text-align: center;
  max-width: 1920px;
  height: 420px;
  line-height: 160px;
  background: #F4F4F4;
  overflow: hidden;
}

.img-font {
  right: 20px;
  top: 30px;

  .font-1 {
    font-size: 42px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.17px;

    background: linear-gradient(270deg, #26DEFA 0%, #269FFA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .font-2 {
    font-size: 24px;
    font-weight: normal;
    line-height: 0px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #666666;
  }
}

.text-hover {
  text-decoration: none;
}

.text-hover:hover {
  opacity: 1;
  text-decoration: underline;
  text-decoration-color: #2b85e4;
  color: #2b85e4;
}

.gongxu-item-cus {
  height: 147px;
  box-shadow: 0px 5px 20px 0px rgba(107, 131, 200, 0.13);
  border-radius: 20px;
  border: 2px solid #FFFFFF;
}

.danbao-content {
  margin-top: 10px;

  .gongxu-list {
    margin-top: 10px;

    .gongxu-cell {
      padding: 10px;
      border: 1px solid #D8D8D8;
      border-radius: 12px;
      height: 78px;

      .gongxu-info {
        font-size: 13px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0em;
        color: #777777;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .gongxu-item {
        width: 100%;
        display: flex;

        .title-tg {
          padding-left: 10px;
          padding-right: 10px;
          height: 22px;
          border-radius: 100px;
          background: #FF6600;
          text-align: center;
          color: #fff;
          margin-right: 15px;
        }

        .title {
          text-align: left;
          font-size: 14px;
          font-weight: bold;
          line-height: 25px;
          letter-spacing: 0px;
        }
      }
    }
  }

  .danbao-list {
    margin-top: 10px;

    .danbao-cell {
      padding: 10px;

      &-btns {
        display: flex;
      }

      &-btn1 {
        background: linear-gradient(-90deg, #FF8726, #F65F13);
        box-shadow: 0px 2px 13px 0px rgba(246, 96, 20, 0.31);
        border-radius: 19px;
        width: 114px;
        height: 31px;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }

      &-btn2 {
        width: 126px;
        height: 31px;
        background: linear-gradient(-90deg, #428FEE, #305DDC);
        box-shadow: 0px 3px 8px 0px rgba(99, 92, 255, 0.27);
        border-radius: 19px;

        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }

      &-title {
        font-size: 14px;
        font-weight: normal;
        line-height: 25px;
        color: #FFFFFF;
        font-weight: bold;
      }

      &-description {
        font-size: 13px;
        font-weight: normal;
        line-height: normal;
        color: #269FFA;
        margin-bottom: 10px;
      }

    }
  }

  .danbao-content-top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    .top-font {
      font-size: 26px;
      font-weight: bold;
      line-height: normal;
    }

    .top-font-right {
      font-size: 14px;
      font-weight: 350;
      line-height: 26px;
      letter-spacing: 0em;
      color: #999999;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.mer-card-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .sroll-product {
    margin-top: 10px;

    .imgs {
      margin-right: 15px;
      position: relative;

      .usdt-bottom {
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        text-align: center;

        font-size: 12px;
        font-weight: 900;
        line-height: 26px;

        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

:deep(.v-infinite-scroll__side) {
  padding: 3px !important;
}

.v-infinite-scroll--horizontal {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.v-infinite-scroll--horizontal:hover {
  overflow-x: scroll !important;
}

.custom-font {
  font-size: 14px;
  text-align: left !important;
  font-weight: 500;
  color: #FF6E26;
  padding-top: 5px;
}

.custom-font-cus {
  font-weight: 480;
  font-size: 14px;
  color: #6B6C6D;
  height: 40px;
}

.mer-card-title {
  height: 64px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  .mer-level {
    font-size: 13px;
    color: #269FFA;
  }

  .mer-name {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
  }

  .avatar {
    height: 64px;
    margin-right: 15px;
  }
}

.mer-card {
  margin-bottom: 8px;
}

.tbs-font {
  width: 72px;
  height: 26px;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  margin-left: 10px;
  //color: #3D3D3D;
}

.model-content-all {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
}

.model-content-one {
  width: 1600px;
  margin: 180px auto 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 30px 0 0 0;
}

.model-content {
  min-height: 1800px;
  width: 1600px;
  margin: 180px auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;

  padding: 30px 0 0 0;

  .model-content-top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .top-font-right {
      font-size: 14px;
      font-weight: 350;
      line-height: 26px;
      letter-spacing: 0em;
      color: #999999;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.msg-cell {
  padding: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0em;
  border-bottom: 1px solid #D8D8D8;
}

.login-btn {
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  color: #269FFA;
  cursor: pointer;
}

.welcome {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
}

.right-msg {
  position: absolute;
  right: 0;
  top: 0;
  height: 420px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  .msg-content {
    width: 226px;
    height: 420px;
    display: flex;
    flex-direction: column;

    .top-login {
      height: 68px;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      justify-content: flex-start;

      .avatar {
        margin-right: 15px;
      }
    }

    .msg-content-bottom {
      flex: 1;

      .message {
        padding: 10px 10px 0 10px;
        font-size: 14px;
        font-weight: bold;
        line-height: normal;
      }
    }
  }
}
</style>
