import request from '@/utils/api_req.js'

/**
 * 是否申请实体卡
 * @returns {*}
 */
export function isCreate() {
  return request.get('u/cards/isCreate')
}

/**
 * 申请实体卡
 */
export function create(data) {
  return request.post('u/cards/create', data)
}

/**
 * 实体卡信息
 * @returns {*}
 */
export function cardInfo() {
  return request.get('u/cards/cardsInfo')
}

/**
 * 实体卡 卡背面信息
 * @returns {*}
 */
export function cardBackInfo() {
  return request.get('u/cards/cardsNoInfo')
}

/**
 * 实体卡交易信息
 * @returns {*}
 */
export function cardTransactions(params) {
  return request.get('u/cards/transferList', params)
}

/**
 * 交易比率
 * @returns {*}
 */
export function queryTransferRate(data) {
  return request.post('u/cards/transferRate', data)
}

/**
 * 交易充值
 * @returns {*}
 */
export function transferIn(data) {
  return request.post('u/cards/transferIn', data)
}

/**
 * 交易转出
 * @returns {*}
 */
export function transferOut(data) {
  return request.post('u/cards/transferOut', data)
}


/**
 * 实体卡邮寄
 * @returns {*}
 */
export function cardPostSave(addressId) {
  return request.get('u/cards/cardPost/'+ addressId)
}

/**
 * 实体卡邮寄状态
 * @returns {*}
 */
export function cardPostStatus() {
  return request.get('u/cards/postStatus')
}

/**
 * 实体卡邮寄签收
 * @returns {*}
 */
export function cardPostSign() {
  return request.get('u/cards/postSign')
}

